import React, { useEffect, useRef } from "react";
import anychart from "anychart";
import "./AnychartThemes/market_forecast.js";

export const AnyChartSimplifiedFigure2 = ({
	data,
	topicName,
	segmentationName,
	years,
	valueDisplayOption,
}) => {
	const anyChartSimplifiedFigure2 = useRef(null);

	useEffect(() => {
		const chart = anychart;

		// SET DATA
		let dataSet = chart.data.set(data);

		// MAP DATA
		let SeriesFYLY = dataSet.mapAs({ x: 0, value: 1 });

		let columnChart = chart.column();

		// LICENSE KEY
		chart.licenseKey("marketforecast.com-f857f242-9dccc95c");

		// THEMES
		chart.theme("marketForecast");

		const currentYear = new Date().getFullYear();

		// CREDITS
		columnChart
			.credits()
			.enabled(true)
			.url("https://www.marketforecast.com")
			.logoSrc("https://www.marketforecast.com/favicon.ico")
			.text(`© Market Forecast ${currentYear}`);

		columnChart
			.title()
			.enabled(true)
			.useHtml(true)
			.padding([0, 0, 10, 0])
			.text(
				`<span style="font-size: 16px;font-weight:bold;">${topicName} Market Size by ${segmentationName}</span>` +
					'<br/><span  style="color:#929292; font-size: 12px;">' +
					`Market Size ${years[0]}-${years[1]}</span>`
			);

		// COLUMNS SETTINGS
		columnChart.padding(20, 5, 5, 5);

		// GRID SETTINGS
		columnChart.yGrid(true).xGrid(true).xMinorGrid(true).yMinorGrid(true);

		// LEGENDS SETTINGS
		columnChart.legend().enabled(true).padding({ top: 10, bottom: 30 });
		columnChart.legend().position("bottom");

		// XAXIS
		columnChart.xAxis().staggerMode(true);
		columnChart.xAxis().staggerMaxLines(4);

		// YAXIS
		columnChart
			.yAxis(0)
			.title(`Market size in US$ ${valueDisplayOption}`)
			.title()
			.padding(20)
			.fontColor("#385D8A");

		columnChart.labels().format(function () {
			return anychart.format.number(this.value, {
				groupsSeparator: ",",
			});
		});

		//  Grid Settings
		columnChart.xGrid(true);
		columnChart.yGrid(true);

		// DATA VIZ
		let columnSeriesFYLY = columnChart.column(SeriesFYLY);
		columnSeriesFYLY.name(`${years[0]}-${years[1]}`);

		columnSeriesFYLY.normal().fill("#385D8A", 0.7);
		columnSeriesFYLY.hovered().fill("#385D8A", 0.5);
		columnSeriesFYLY.selected().fill("#385D8A", 0.9);

		columnSeriesFYLY.normal().stroke("#ffffff", 1, "", "");
		columnSeriesFYLY.hovered().stroke("#ffffff", 1, "", "");
		columnSeriesFYLY.selected().stroke("#ffffff", 1, "", "");

		// GLOBALS TOOLTIPX
		columnChart.tooltip().title().fontColor("#FFFFFF").fontSize(14);
		columnChart.tooltip().fontColor("#FFFFFF").fontSize(12).padding(10);

		columnSeriesFYLY.tooltip().format(function () {
			return (
				`Market size ${years[0]}-${years[1]}: ` +
				anychart.format.number(this.value, {
					groupsSeparator: ",",
					decimalSeparator: ".",
				}) +
				` US$ ${valueDisplayOption}`
			);
		});

		columnChart.container(anyChartSimplifiedFigure2.current).draw();

		// CONTEXT MENU SETTINGS
		columnChart.contextMenu().itemsFormatter(function (items) {
			items["save-chart-as"].subMenu["save-chart-as-png"].action =
				function () {
					columnChart.saveAsPng({
						width: 1000,
						height: 1000,
						quality: 0.3,
						filename: `SS02_${topicName}_Market_Size_by_${segmentationName}`,
					});
				};
			items["save-chart-as"].subMenu["save-chart-as-jpg"].action =
				function () {
					columnChart.saveAsJpg({
						width: 1000,
						height: 1000,
						quality: 0.3,
						forceTransparentWhite: false,
						filename: `SS02_${topicName}_Market_Size_by_${segmentationName}`,
					});
				};
			items["save-chart-as"].subMenu["save-chart-as-svg"].action =
				function () {
					columnChart.saveAsSvg({
						width: 1000,
						height: 1000,
						filename: `SS02_${topicName}_Market_Size_by_${segmentationName}`,
					});
				};
			items["save-chart-as"].subMenu["save-chart-as-pdf"].action =
				function () {
					columnChart.saveAsPdf({
						paperSize: "a4",
						landscape: true,
						filename: `SS02_${topicName}_Market_Size_by_${segmentationName}`,
					});
				};
			return items;
		});

		// EXPORT
		chart.exports.filename(
			`SS02_${topicName}_Market_Size_by_${segmentationName}`
		);

		return () => {
			columnChart.dispose();
		};
	}, [data, topicName, segmentationName, years, valueDisplayOption]);

	return (
		<div
			ref={anyChartSimplifiedFigure2}
			style={{ width: "600px", height: "450px" }}
		></div>
	);
};

import React, { useState, useEffect } from "react";
import { useAuth } from "../authentication/AuthProvider";
import { Link, useParams } from "react-router-dom";
import { PageTitle } from "../components/PageTitle";
import { Breadcrumbs } from "../components/Breadcrumbs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@awesome.me/kit-524d1d64d7/icons/classic/solid";
import { faChartMixed } from "@awesome.me/kit-524d1d64d7/icons/duotone/solid";

import { SingleSegmentationOutput } from "./Forecasts/SingleSegmentationOutput/SingleSegmentationOutput.page.jsx";
import { VersusSegmentationOutput } from "./Forecasts/VersusSegmentationOutput/VersusSegmentationOutput.page.jsx";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export function StudyForecast() {
	let { catalogID } = useParams();

	const [forecastFiles, setForecastFiles] = useState(null);

	const [study, setStudy] = useState(null);

	const [state, setState] = useState({
		loading: true,
		loadingFiles: true,
		tag: {
			name: "",
		},
	});

	const auth = useAuth();

	useEffect(() => {
		async function fetchDetails() {
			const endpoint = "api/study/get/";
			if (!catalogID) {
				// handle this
			}

			let url = endpoint + catalogID;
			auth.apiGet(url).then((json) => {
				if (!json) {
					return;
				}
				setStudy(json);

				setState({
					loading: false,
					tag: json.tag,
				});

				// const fName = formFileName(json);
				// setFileName(fName);
			});
		}

		async function fetchForecastFiles() {
			const endpoint = "api/forecast/get/";
			if (!catalogID) {
				// handle this
			}

			let url = endpoint + catalogID;
			auth.apiGet(url).then((json) => {
				if (!json) {
					return;
				}

				json.map((s) => {
					s.singleSegment = JSON.parse(s.singleSegment);
					s.versusSegment = JSON.parse(s.versusSegment);
				});

				setForecastFiles(json);
				console.log(json);

				setState({
					loadingFiles: false,
				});

				console.log(forecastFiles);
			});
		}

		fetchForecastFiles();
		fetchDetails();
	}, [auth, catalogID]);

	return study === null ? null : (
		<div>
			<PageTitle>{study.title}</PageTitle>
			{renderBreadcrumbs(study.title, catalogID)}

			<hr></hr>
			<h2 className="mt-2">
				<FontAwesomeIcon icon={faChartMixed} size="2x" /> Forecast:
				Global and Segmented Forecasts Data & Visualization
			</h2>

			{forecastFiles === null ? null : (
				<Tabs className="mt-4">
					<TabList>
						{forecastFiles.map((f, index) => (
							<Tab key={index}>By {f.segment}</Tab>
						))}
					</TabList>

					{forecastFiles === null ? (
						<div>No data available</div>
					) : (
						<div>
							{forecastFiles.map((f, index) => (
								<TabPanel key={index}>
									{forecastFiles.map((seg, segIndex) => (
										<li
											className="list-group-item list-group-item-action list-group-item-secondary"
											key={segIndex}
										>
											<a
												className="link-dark"
												href="#"
												onClick={(e) => {
													e.preventDefault();
													const element =
														document.getElementById(
															`chapter${
																segIndex + 1
															}`
														);
													if (element) {
														element.scrollIntoView({
															behavior: "smooth",
															block: "start",
														});
														const yOffset = -60; // Adjust this value as needed
														const y =
															element.getBoundingClientRect()
																.top +
															window.scrollY +
															yOffset;
														window.scrollTo({
															top: y,
															behavior: "smooth",
														});
													}
												}}
											>
												{segIndex + 1}. {""}
												{segIndex === 0
													? `${f.singleSegment[0].topicName} Market by ${f.segment} Overview`
													: `${
															f.singleSegment[0]
																.topicName
													  } Market ${
															f.segment
													  } by ${
															f.versusSegment[
																segIndex - 1
															].segmentations[1]
																.name
													  } `}
											</a>
										</li>
									))}
									<div className="row">
										{f.singleSegment === null ||
										f.singleSegment === undefined ||
										!Array.isArray(f.singleSegment) ||
										f.singleSegment.length === 0 ||
										f.singleSegment[0].segmentations ===
											null ||
										f.singleSegment[0].segmentations ===
											undefined ? (
											<div>
												No data available for single
												segment
											</div>
										) : (
											<SingleSegmentationOutput
												output={f.singleSegment}
												topicName={
													f.singleSegment[0].topicName
												}
												valueDisplayOption={"millions"}
												decimalsOption={"0."}
											/>
										)}

										{f.versusSegment === null ||
										f.versusSegment === undefined ||
										!Array.isArray(f.versusSegment) ||
										f.versusSegment.length === 0 ||
										f.versusSegment[0].segmentations ===
											null ||
										f.versusSegment[0].segmentations ===
											undefined ? (
											<div>
												No data available for versus
												segment
											</div>
										) : (
											<VersusSegmentationOutput
												output={f.versusSegment}
												topicName={
													f.versusSegment[0].topicName
												}
												valueDisplayOption={"millions"}
												decimalsOption={"0."}
											/>
										)}
									</div>
								</TabPanel>
							))}
						</div>
					)}
				</Tabs>
			)}
		</div>
	);
}

function renderBreadcrumbs(title, catalogID) {
	if (!title) {
		return <Breadcrumbs>All Studies</Breadcrumbs>;
	}

	return (
		<Breadcrumbs>
			<Link
				key={45}
				to="/catalog"
				className="link-dark text-decoration-none"
			>
				<FontAwesomeIcon icon={faArrowLeft} className="me-2" /> All
				Studies
			</Link>
			<Link
				key={46}
				to={"/studyDetails/" + catalogID}
				className="link-dark text-decoration-none"
			>
				<FontAwesomeIcon icon={faArrowLeft} className="me-2" />
				{title}
			</Link>
			Forecast: Global and Segmented Forecasts Data & Visualization
		</Breadcrumbs>
	);
}

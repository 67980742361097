import React, { useEffect, useRef, useState } from "react";
import AnyChart from "anychart";
import "./AnychartThemes/market_forecast.js"

export const AnychartSimplifiedPieChart = (props) => {
    const {
        data,
        year,
        firstYear,
        lastYear,
        topicName,
        segmentationName,
        valueDisplayOption,
        FYValues,
        LYValues,
        yearIndex,
        decimalsOption,
        onNextStep,
        onAddChartsForExportWord,
    } = props;

    const chartContainerRef = useRef(null);
    const [total, setTotal] = useState(null);


    useEffect(() => {
        sumYearsValue();

        const _AnyChart = AnyChart;
        const chart = _AnyChart;

        chart.theme("marketForecast");

        let dataSet = chart.data.set(data);
        let SeriesFY = dataSet.mapAs({ x: 0, value: yearIndex });

        let pieChart = chart.pie(SeriesFY);

        chart.licenseKey("marketforecast.com-f857f242-9dccc95c"); // set key on .env 

        pieChart
            .credits()
            .enabled(false)

        // pieChart.padding(5, 5, 5, 5);
        pieChart.labels().position("outside");
        pieChart.labels().fontSize(11);
        pieChart.labels().format(function () {
            let currentLabel = this.getData("x");
            let percentage = this.getStat("percentValue").toFixed(2) + "%";
            return currentLabel + " " + percentage;
        });

        pieChart.legend().enabled(false);
        pieChart.background().stroke(null);
        pieChart.innerRadius("40%");

        const FY = firstYear;
        
        if (yearIndex === 1) {
            pieChart
                .title()
                .enabled(true)
                .text(`${FY} US$ ${total} ${valueDisplayOption}`)
                .orientation("bottom")
                .align("center")
                .fontSize(12);
                
            pieChart
                .label()
                .enabled(true)
                .text(FY)
                .position("center")
                .anchor("center")
                .hAlign("center")
                .vAlign("middle")
                .offsetY(-10)
                .offsetX(-5)
                .fontColor("#003A70")
                .fontWeight("bold")
                .fontSize(13);
        } else {
            pieChart
                .title()
                .enabled(true)
                .text(`${lastYear} US$ ${total} ${valueDisplayOption}`)
                .orientation("bottom")
                .align("center")
                .fontSize(12);
            pieChart
                .label()
                .enabled(true)
                .text(lastYear)
                .position("center")
                .anchor("center")
                .hAlign("center")
                .vAlign("middle")
                .offsetY(-10)
                .offsetX(-5)
                .fontColor("#003A70")
                .fontWeight("bold")
                .fontSize(13);
        }

        // TOOLTIPTITLE
        pieChart
            .tooltip()
            .title()
            .fontColor("#FFFFFF")
            .fontSize(14);
        // TOOLTIP CONTENT 
        pieChart
            .tooltip()
            .fontColor("#FFFFFF")
            .fontSize(12)
            .padding(10)
            .format("{%Value}");
        pieChart
            .tooltip()
            .titleFormat(function () {
                return "" + this.getData("x");
            })
            .format(function () {
                const year = (yearIndex === 1) ? FY : lastYear
                return `${year} : ` + _AnyChart.format.number(this.getData("value"), {
                    groupsSeparator: ",",
                    decimalSeparator: ".",
                }) + ` US$ ${valueDisplayOption}`;
            });

        pieChart.stroke("1 #FFFFFF");

        // Adjust chart size to half of the container
        // pieChart.width("150%");
        // pieChart.height("150%");

        pieChart.container(chartContainerRef.current).draw();

        return () => {
            pieChart.dispose();
        }
        
    }, [data,total, decimalsOption, valueDisplayOption]); 

    const roundTo = (num, decimals) => {
        const factor = Math.pow(10, decimals);
        return Math.round(num * factor) / factor;
    };

    const toFixDecimate3 = (cell) => {
        if (decimalsOption === "0.") {
            return Number.isInteger(cell) ? cell : Math.round(cell);
        }
        if (decimalsOption === ".0") {
            return roundTo(cell, 1).toFixed(1);
        }
        if (decimalsOption === ".00") {
            return roundTo(cell, 2).toFixed(2);
        }
        if (decimalsOption === ".000") {
            return roundTo(cell, 3).toFixed(3);
        }
    };

    const formatNumber = (num) => {
        if (num) {
            let [int, decimal] = num.toString().split(".");
            int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return decimal ? `${int}.${decimal}` : int;
        } else {
            return num;
        }
    };

    const sumYearsValue = () => {
        if (FYValues) {
            let fy = FYValues.reduce((acc, value) => acc + parseFloat(value), 0);
            fy = toFixDecimate3(fy);
            setTotal(formatNumber(fy));
        } else {
            let ly = LYValues.reduce((acc, value) => acc + parseFloat(value), 0);
            ly = toFixDecimate3(ly);
            setTotal(formatNumber(ly));
        }
    };

    return <div ref={chartContainerRef} className="chart" style={{ height: "100%", width:"100%" }} />;
}



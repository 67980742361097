/*
	Useful resources: 
	- https://react-pdf-viewer.dev/examples/include-the-default-toolbar-in-the-full-screen-mode/
	- https://react-pdf-viewer.dev/examples/remove-some-parts-from-the-default-toolbar/
	- https://github.com/react-pdf-viewer/examples/blob/main/get-file/CustomizeFileNameExample.tsx
 
*/

import React from "react";

import {
	Viewer,
	Worker,
	OpenFile,
	DocumentLoadEvent,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { getFilePlugin } from "@react-pdf-viewer/get-file";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export function PdfViewer(props) {
	const getFilePluginInstance = getFilePlugin({
		fileNameGenerator: (file) => {
			if (!props.fileName) {
				return file.name;
			}

			return props.fileName;
		},
	});

	const { DownloadButton } = getFilePluginInstance;

	const toolbarPluginInstance = toolbarPlugin({
		fullScreenPlugin: {
			getFullScreenTarget: (pagesContainer) =>
				pagesContainer.closest('[class="rpv-core__viewer"]'),
			renderExitFullScreenButton: (props) => <></>,
		},
	});
	const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

	const transform = (slot) => ({
		...slot,
		// Add buttons that you wish to remove from default toolbar here
		// Documentation
		Open: () => <></>,
		SwitchTheme: () => <></>,
		ShowProperties: () => <></>,
		ShowPropertiesMenuItem: () => <></>,

		// Custom filenames on download
		Download: () => <DownloadButton />,
	});
	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		setInitialTab: () => Promise.resolve(1),
		renderToolbar: () => null,
	});

	const handleDocumentLoad = (e) => {
		const { activateTab } = defaultLayoutPluginInstance;

		// Activate the bookmark tab whose index is `1`
		activateTab(1);
	};

	let winHeight = window.innerHeight - 56; // 56 = height of navbar

	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.js">
			<div style={{ height: winHeight }}>
				<div
					className="rpv-core__viewer"
					style={{
						border: "1px solid rgba(0, 0, 0, 0.3)",
						display: "flex",
						flexDirection: "column",
						height: "100%",
					}}
				>
					<div
						style={{
							alignItems: "center",
							backgroundColor: "#eeeeee",
							borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
							display: "flex",
							padding: "0.25rem",
						}}
					>
						<Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
					</div>
					<div
						style={{
							flex: 1,
							overflow: "hidden",
						}}
					>
						<Viewer
							defaultScale="PageFit"
							fileUrl={props.fileURL}
							plugins={[
								defaultLayoutPluginInstance,
								toolbarPluginInstance,
								getFilePluginInstance,
							]}
							onDocumentLoad={handleDocumentLoad}
						/>
					</div>
				</div>
			</div>
		</Worker>
	);
}

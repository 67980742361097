import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../authentication/AuthProvider";
import { Loading } from "../components/Loading";
import { PageTitle } from "../components/PageTitle";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { StudyDetailsActions } from "../components/StudyDetailsActions";
import { StudyDetailsDataTable } from "../components/StudyDetailsDataTable";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@awesome.me/kit-524d1d64d7/icons/classic/solid";

export function TestDetail() {
	let { catalogID } = useParams();

	const [study, setStudy] = useState(null);
	const [hasForecastFiles, setHasForecastFiles] = useState(false);

	const [hasStudyFiles, setHasStudyFiles] = useState({
		hasForecast: false,
		hasPDFs: false,
	});

	const [state, setState] = useState({
		loading: true,
		tag: {
			name: "",
		},
	});

	const auth = useAuth();

	useEffect(() => {
		async function fetchDetails() {
			const endpoint = "api/study/get/";
			if (!catalogID) {
				// handle this
			}

			let url = endpoint + catalogID;
			auth.apiGet(url).then((json) => {
				if (!json) {
					return;
				}
				setStudy(json);

				setState({
					loading: false,
					tag: json.tag,
				});
			});
		}

		async function checkStudyFiles() {
			const endpoint = "api/study/StudyFiles/";

			if (!catalogID) {
				// handle this
			}

			let url = endpoint + catalogID;
			auth.apiGet(url).then((json) => {
				if (!json) {
					return;
				}

				setHasStudyFiles({
					hasForecast: json.hasForecast,
					hasPDFs: json.hasPDFs,
				});
			});
		}

		checkStudyFiles();
		fetchDetails();
	}, [auth, catalogID]);

	return state.loading ? (
		<div>
			<Loading />
		</div>
	) : (
		<div>
			<PageTitle>{study.title}</PageTitle>
			{renderBreadcrumbs(study.tags[0])}
			<hr></hr>
			{Object.values(hasStudyFiles).every((item) => item === false) ? (
				<div class="alert alert-success" role="alert">
					<h4 class="alert-heading">
						This study will be available shortly
					</h4>
					<p>
						The content for this study is currently not available.
						The content is being updated, files will be available
						shortly.
					</p>
				</div>
			) : (
				<StudyDetailsActions
					study={study}
					hasStudyFiles={hasStudyFiles}
				></StudyDetailsActions>
			)}
		</div>
	);
}

function renderBreadcrumbs(tag) {
	if (!tag) {
		return <Breadcrumbs>All Studies</Breadcrumbs>;
	}

	return (
		<Breadcrumbs>
			<Link to="/catalog" className="link-dark text-decoration-none">
				<FontAwesomeIcon icon={faArrowLeft} className="me-2" /> All
				Studies
			</Link>
			{tag.name}
		</Breadcrumbs>
	);
}
